import React, {Suspense} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Preloader from "../components/blocks/Preloader";

const Home = React.lazy(() => import("../pages/home/Home"));
const About = React.lazy(() => import("../pages/about/About"));
const Contact = React.lazy(() => import("../pages/contact/Contact"));
const Pricing = React.lazy(() => import("../pages/pricing/Pricing"));
const Subscribe = React.lazy(() => import("../pages/subscribe/Subscribe"));
const RenewSubscription = React.lazy(() =>
  import("../pages/subscribe/RenewSubscription")
);
const ValentineSubscription = React.lazy(() =>
  import("../pages/valentine/ValentineSubscription")
);

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/subscribe" component={Subscribe} />
          <Route exact path="/subscribe-renewal" component={RenewSubscription} />
          <Route exact path="/gift" component={ValentineSubscription} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default MainRoutes;
