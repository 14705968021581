import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import MainRoutes from "./routes";

import "./assets/css/tailwind.css";

import GA4React from "ga-4-react";
const ga4react = new GA4React("G-ETRWF9EZSL");

(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log("Success."))
    .catch((err) => console.log("Failure."))
    .finally(() => {
      ReactDOM.render(<MainRoutes />, document.getElementById("root"));
    });
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
