import React from "react";
import LogoIcon from "../../assets/images/svg/logoIcon.svg";

const Preloader = () => {
  return (
    <div className="bg-white min-h-screen w-full relative">
      <div className="relative min-h-screen flex justify-center items-center">
        <img src={LogoIcon} className="w-20 rotate-preloader-icon" alt="Logo" />
      </div>
    </div>
  );
};

export default Preloader;
